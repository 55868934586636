import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
const Home = () =>import ('../views/index.vue') //首页  
const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: "Yourstar Testcoin Claim", requireAuth: false }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/' /*匹配不到路由时跳转地址*/  
  } ,
]
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title;
    // if (to.meta.requireAuth == true) {
    //     //需要登录权限进入的路由
    //     next({path:'/'})
    // } else {
    //     //不需要登录权限
        return next();
    // }
})

export default router
