const en = {
    name: '姓名',
    age: '男',

    lang_1: 'Spot Center',
    lang_2: 'OTC trading',
    lang_3: 'Contract Center',
    lang_4: 'Agent',
    lang_5: 'More',
    
}
export default en;