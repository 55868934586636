import { createStore } from 'vuex'

export default createStore({
  state: {
    connect:{
      isConnect:false,
      address:'',
      balance:'',
      chainId:1,
      token:'',
    },
  },
  mutations: {
    changeconnect(state,data){
      state.connect = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
