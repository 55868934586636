
// import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n'
// 引入各个语言配置文件
import zh_CN from './config/zh';
import en_US from './config/en';

// 语言库
const messages = {
    'zh_CN': zh_CN,
    'en_US': en_US
  };
const langDefault = localStorage.getItem('localeLang') || 'zh_CN'; //默认显示的语言 

// 创建vue-i18n实例i18n
const i18n = createI18n({
    locale: langDefault,		
    messages
  })
  
// 暴露i18n
export default i18n;