import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//语言
import i18n from './lang'; //this.$i18n.locale = 语言编码;切换语言    语法用法:{{$t("name")}}
//http请求
import Http from './http' //vue全局请求帮助库
//工具类
import Utils from './utils';

//创建app实例
const app = createApp(App);
app.use(store);  //挂载vuex
app.use(router); //挂载路由
app.use(i18n); //挂载语言
app.use(Http);  //挂载vuex
app.config.globalProperties.$http = Http; //挂载 resource
app.config.globalProperties.$utils = Utils; //挂载帮助类库模块
// app.component("vue-upload", upload) ;
// app.component("vue-input", baseinput);
// app.component("vue-nodata", nodata) 
app.mount('#app')
// createApp(App).use(store).use(router).mount('#app')
