
import axios from "axios"
import utils from "../utils/index.js"
import store from "../store/index.js"
const  HTTP = {};
/**
 * vue-resource get请求不带参数不带请求头
 * @param {请求的地址} url 
 * @param {结果回调方法} back 
 */
HTTP.get = function(api,params) {
    return	axios({
		method:'get',
		url:api,
		params:params
	})
	
	// .then(response => response.data).catch((err)=>{
	// 	console.log(err)
	// })
};
/**
 * vue-resource POST请求不带参数不带请求头
 * @param {请求的地址} url 
 * @param {结果回调方法} back 
 */
HTTP.post = function(api,data) {
    return axios({
		method:'post',
		url:api,
		headers:{'Content-Type':'aplication/json','x-access-token':store.state.connect.token,},
		// headers:{'Content-Type': 'multipart/form-data','appKey':store.state.appKey,'cq-social-token':store.state.token,'cq-sign-key':sign},
		data:data
	})
};

HTTP.postFromData = function(api,data) {
    var formData = new FormData();
    Object.getOwnPropertyNames(data).forEach(function(key){
          formData.append(key, data[key]);
    })
      return axios({
      method:'post',
      url:api,
      // baseURL:url,
      // headers:{'Content-Type':'aplication/json'},
      headers:{'Content-Type': 'multipart/form-data'},
      data:formData
    })
    
    // .then(response => response.data).catch((err) => {
    //   console.log(err)
    // })
  };

export default HTTP