<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// Vue 3.0 中通过 getCurrentInstance 方法获取当前组件的实例，然后通过 ctx 属性获得当前上下文，
// ctx.$router 是 Vue Router 实例，里面包含了 currentRoute 可以获取到当前的路由信息
import { useRoute} from 'vue-router' //获取路由
import { ref, computed, watch, onMounted,getCurrentInstance } from 'vue'
// ref 模块是用来声明简单数据类型的，例如,string , number ,boolean 等
// reactive 模块是用来声明复杂数据类型的，例如，数组，对象等
// setup() :开始创建组件之前，在beforeCreate和created之前执行。创建的是data和method
// onBeforeMount() : 组件挂载到节点上之前执行的函数。
// onMounted() : 组件挂载完成后执行的函数。(用来数据初始化，接收路由参数)
// onBeforeUpdate(): 组件更新之前执行的函数。
// onUpdated(): 组件更新完成之后执行的函数。
// onBeforeUnmount(): 组件卸载之前执行的函数。
// onUnmounted(): 组件卸载完成后执行的函数
// onActivated(): 被包含在中的组件，会多出两个生命周期钩子函数。被激活时执行。
// onDeactivated(): 比如从 A 组件，切换到 B 组件，A 组件消失时执行。
// onErrorCaptured(): 当捕获一个来自子孙组件的异常时激活钩子函数（以后用到再讲，不好展现）。
// import WalletConnect from "@/utils/wallet/connect.js"
// import { onMounted,getCurrentInstance, reactive, toRefs } from 'vue';
export default {
  name: 'App',
  setup() {
    const route = useRoute() ;
    const { proxy } = getCurrentInstance();
    // 先执行setup,再执行onMounted
    onMounted(async ()=>{
      // 组件挂载的过程
      //Load cached data 
      if (sessionStorage.getItem("storedata") ) {
          proxy.$store.replaceState(Object.assign({}, proxy.$store.state,JSON.parse(sessionStorage.getItem("storedata"))))
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload",()=>{
          sessionStorage.setItem("storedata",JSON.stringify(proxy.$store.state))
      });
      // 兼容iphone手机
      window.addEventListener("pagehide",()=>{
          sessionStorage.setItem("storedata",JSON.stringify(proxy.$store.state))
      });
    })
    return {}
  }
}
</script>
<style lang="css" src="./assets/common.css">
</style>
